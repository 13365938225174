var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative w-full"},[(!_vm.hasIcon && _vm.label)?_c('label',{staticClass:"flex items-center justify-between text-sm text-gray-800 mb-2",class:{
      'font-semibold': _vm.scheme === _vm.SCHEMES.primary,
      'text-red-600': _vm.invalid,
    },attrs:{"for":_vm.id}},[_c('p',[_vm._v(_vm._s(_vm.label)+" "),(_vm.isOptional)?_c('span',{staticClass:"text-gray-600 text-xs ml-2"},[_vm._v("Optional")]):_vm._e()]),(_vm.isActiveClearBtn)?_c('button',{staticClass:"text-[color:var(--group-colors-crimson-active,#DA133E)] text-sm font-semibold",attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();return _vm.clearField.apply(null, arguments)}}},[_vm._v(" Clear location ")]):_vm._e()]):_vm._e(),_c('vue-google-autocomplete',{ref:"mapsInput",class:[
      { 'input--filled': _vm.internalValue && _vm.internalValue.length > 0 },
      { 'border-red-600': _vm.invalid },
      { 'border-gray-300': !_vm.invalid },
      { disabled: _vm.disabled },
      { 'py-5 pl-12 pr-4': _vm.hasIcon },
      { 'py-4 px-5': !_vm.hasIcon },
      { 'h-56px': !_vm.customHeight },
    ],attrs:{"id":_vm.id,"classname":"input border focus:outline-none w-full text-gray-900 bg-white rounded shadow text-base placeholder-gray-600","placeholder":_vm.placeholder,"types":_vm.searchTypes},on:{"inputChange":_vm.handleChange,"placechanged":_vm.getAddressData,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.emitAddress.apply(null, arguments)}}}),(_vm.hasIcon && _vm.label)?_c('span',{staticClass:"icon-wrapper absolute top-0 left-0 h-full flex items-center ml-4 text-gray-400 transition-colors duration-200 ease-out pointer-events-none"},[_c('label',{staticClass:"pointer-events-none opacity-0 w-0 h-0",attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.label))]),_vm._t("icon")],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }