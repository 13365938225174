<template>
  <div class="relative" :class="[{ 'w-full': !customWidth }]">
    <ChevronDownIcon
      v-if="showChevron"
      :class="{ focused }"
      class="chevron-icon absolute right-0 w-2 mr-6 mt-5"
    ></ChevronDownIcon>
    <DatePicker @change="updateFromCalendar"
      :disabled-date="dateRestrictFunction"
      :value="value"
      :value-type="'M/D/YYYY'"
      >
      <template v-slot:input>
        <input
          :readonly="readonly"
          autocomplete="off"
          autocorrect="false"
          spellcheck="false"
          :input-class="''"
          @input="updateValue"
          @focus="onFocus"
          @blur="focused = false"
          :id="id"
          :name="name"
          :type="type"
          ref="input"
          class="input cursor-pointer h-full border w-full text-gray-900 bg-white rounded shadow text-sm placeholder-gray-500 focus:outline-none transition-colors duration-200 ease"
          :class="[
            { 'h-56px': !customHeight },
            { 'input--filled': value && value.length > 0 },
            { 'border-red-600': invalid },
            { 'border-gray-300': !invalid },
            { disabled: disabled },
            { 'py-5 pl-12 pr-4': hasIcon && !showLabel && label && !compact },
            { 'py-3 pl-14 pr-4': hasIcon && showLabel && label && !compact },
            { 'pt-3 pb-3 px-4': !hasIcon && label && !compact },
            { 'py-6 px-4': !label && !compact },
            { 'py-2 pl-10 pr-2': hasIcon && compact },
            { 'py-2 px-4': !hasIcon && compact },
          ]"
          :placeholder="placeholder || (focused && focusedPlaceholder)"
          :maxlength="maxLength"
          :disabled="disabled"
          :value="value"
        />

        <template v-if="label">
          <label
            v-if="!hasIcon"
            class="input__label absolute left-0 text-gray-600 mx-4 pointer-events-none mt-1 origin-top-left transition duration-200 ease-out"
            :class="[{ 'text-red-600': invalid }]"
            :for="id"
          >
            {{ label }}
          </label>

          <label
            v-if="hasIcon && showLabel"
            class="input__label absolute left-0 text-gray-600 mt-2px mr-4 ml-14 pointer-events-none origin-top-left transition duration-200 ease-out"
            :class="[{ 'text-red-600': invalid }]"
            :for="id"
          >
            {{ label }}
          </label>

          <span
            v-if="hasIcon"
            class="icon-wrapper absolute top-0 left-0 h-full flex items-center ml-5 px-1"
          >
            <label :for="id" class="pointer-events-none opacity-0 w-0 h-0">{{ label }}</label>
            <slot name="icon"></slot>
          </span>
        </template>
      </template>
    </DatePicker>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import ChevronDownIcon from '@/assets/icon_chevron_right.svg';
import 'vue2-datepicker/index.css';
import { DateTime } from 'luxon';

export default {
  name: 'CalendarInput',
  components: {
    DatePicker,
    ChevronDownIcon,
  },
  data() {
    return {
      focused: false,
    };
  },
  props: {
    /**
     * id of the input
     */
    id: {
      type: String,
    },
    /**
     * name of the input
     */
    name: {
      type: String,
    },
    /**
     * Placeholder text inside the input
     */
    placeholder: {
      type: String,
      default: '',
    },
    /**
     * Placeholder text inside the input when input is focused
     */
    focusedPlaceholder: {
      type: String,
      default: '',
    },
    /**
     * Input type. Can be changed to password or email, for instance
     */
    type: {
      type: String,
      default: 'text',
    },
    /**
     * The value for the text input
     */
    value: {
      type: String,
    },
    /**
     * The text that will be displayed for the Label
     */
    label: {
      type: String,
    },
    /**
     * Determines whether a field is invalid and should display differently (e.g. a red border)
     */
    invalid: {
      type: Boolean,
      default: false,
    },
    /**
     * The max number of characters of the input field
     */
    maxLength: {
      type: Number,
      default: 524288, // The HTML spec default value
    },
    /**
     * Determines if the field is disabled or not
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Flag specifically for text inputs with icons that should show the label too
     */
    showLabel: {
      type: Boolean,
      default: false,
    },
    /**
     * removes full width styling if true
     */
    customWidth: {
      type: Boolean,
      default: false,
    },
    customHeight: {
      type: Boolean,
      default: false,
    },
    localStringFormat: {
      type: [Object, String],
      default: null,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    showChevron: {
      type: Boolean,
      default: true,
    },
    dateRestrictFunction: {
      type: Function,
      default: () => false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasIcon() {
      return this.$slots.icon;
    },
  },
  methods: {
    updateValue() {
      if (this.$refs.input) {
        this.$emit('input', this.$refs.input.value);
      }
    },
    updateFromCalendar(date) {
      let formatted;
      if (this.localStringFormat) {
        const dateLuxon = DateTime.fromFormat(date, 'L/d/yyyy');
        if (typeof this.localStringFormat === 'string') {
          formatted = dateLuxon.toFormat(this.localStringFormat);
        } else {
          formatted = dateLuxon.toLocaleString({ locale: 'en-us' }, this.localStringFormat);
        }
      } else {
        formatted = `${date.getMonth() > 8 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`}-${
          date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`
        }-${date.getFullYear()}`;
      }

      this.$emit('input', formatted);
    },
    onFocus() {
      this.focused = true;
      this.$emit('focus');
    },
  },
};
</script>

<style>
.mx-icon-calendar,
.mx-icon-clear {
  display: none;
}
</style>

<style scoped lang="scss">
.chevron-icon {
  z-index: 1;
  transform: rotate(90deg);

  &.focused {
    transform: rotate(-90deg);
  }
}

.input__label {
  transform: translateY(0.85rem);
}

.input:focus {
  @apply border-gray-400;
}

.input:focus + .input__label,
.input--filled + .input__label {
  display: none;
}

.input:focus + .input__label {
  @apply text-primary;
}

.input:focus + .icon-wrapper {
  @apply text-primary;
}

.disabled {
  @apply bg-gray-200 shadow-none text-gray-700;
}

.mx-datepicker {
  width: 100%;
  height: 100%;
}

.mx-input-wrapper {
  height: 100%;
}
</style>
