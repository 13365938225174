<template>
  <div class="relative w-full">
    <label
      v-if="!hasIcon && label"
      class="flex items-center justify-between text-sm text-gray-800 mb-2"
      :class="{
        'font-semibold': scheme === SCHEMES.primary,
        'text-red-600': invalid,
      }"
      :for="id"
    >
      <p>{{ label }} <span v-if="isOptional" class="text-gray-600 text-xs ml-2">Optional</span></p>
      <button
        v-if="isActiveClearBtn"
        type="button"
        class="text-[color:var(--group-colors-crimson-active,#DA133E)] text-sm font-semibold"
        @click.stop="clearField"
      >
        Clear location
      </button>
    </label>

    <vue-google-autocomplete
      :id="id"
      ref="mapsInput"
      classname="input border focus:outline-none w-full text-gray-900 bg-white rounded shadow text-base placeholder-gray-600"
      :class="[
        { 'input--filled': internalValue && internalValue.length > 0 },
        { 'border-red-600': invalid },
        { 'border-gray-300': !invalid },
        { disabled: disabled },
        { 'py-5 pl-12 pr-4': hasIcon },
        { 'py-4 px-5': !hasIcon },
        { 'h-56px': !customHeight },
      ]"
      :placeholder="placeholder"
      :types="searchTypes"
      @inputChange="handleChange"
      v-on:placechanged="getAddressData"
      v-on:keypress.enter="emitAddress"
    />

    <span
      v-if="hasIcon && label"
      class="icon-wrapper absolute top-0 left-0 h-full flex items-center ml-4 text-gray-400 transition-colors duration-200 ease-out pointer-events-none"
    >
      <label :for="id" class="pointer-events-none opacity-0 w-0 h-0">{{ label }}</label>
      <slot name="icon"></slot>
    </span>
  </div>
</template>
<script>
// eslint-disable-next-line
import VueGoogleAutocomplete from 'vue-google-autocomplete';

const SCHEMES = {
  primary: 'primary',
  secondary: 'secondary',
};
export default {
  name: 'MapsAutocompleteInput',
  components: {
    VueGoogleAutocomplete,
  },
  data() {
    return {
      SCHEMES,
      focused: false,
      internalValue: null,
    };
  },
  props: {
    /**
     * id of the input
     */
    id: {
      type: String,
    },
    /**
     * name of the input
     */
    name: {
      type: String,
    },
    /**
     * Placeholder text inside the input
     */
    placeholder: {
      type: String,
      default: '',
    },
    isOptional: {
      type: Boolean,
      default: false,
    },
    scheme: {
      type: String,
      default: SCHEMES.primary,
    },
    isActiveClearBtn: {
      type: Boolean,
      default: false,
    },
    /**
     * Input type. Can be changed to password or email, for instance
     */
    type: {
      type: String,
      default: 'text',
    },
    /**
     * The value for the text input
     */
    value: {
      type: String,
    },
    /**
     * The text that will be displayed for the Label
     */
    label: {
      type: String,
    },
    /**
     * Determines whether a field is invalid and should display differently (e.g. a red border)
     */
    invalid: {
      type: Boolean,
      default: false,
    },
    /**
     * The max number of characters of the input field
     */
    maxLength: {
      type: Number,
      default: 524288, // The HTML spec default value
    },
    /**
     * Determines if the field is disabled or not
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Determines if the field has custom height
     */
    customHeight: {
      type: Boolean,
      default: false,
    },
    searchTypes: {
      type: String,
    },
    /**
     * Emit output outside if only value is filled (can be trimmed)
     */
    onKeyEnter: {
      type: Function,
    },
    allowCustomEntries: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasIcon() {
      return this.$slots.icon;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.mapsInput.update(this.value);
      this.internalValue = this.value;
    });
  },
  methods: {
    clearField() {
      this.$refs.mapsInput.$refs.autocomplete.value = '';
      this.internalValue = null;
      this.$emit('updateFullAddress', '');
    },
    // eslint-disable-next-line no-unused-vars
    getAddressData(addressData, placeResultData, id) {
      this.$emit('updateCity', addressData.locality || placeResultData?.name);
      this.$emit('updateState', addressData.administrative_area_level_1);
      this.$emit('updateCounty', addressData.country);
      this.$emit('updateFullAddress', placeResultData ? placeResultData.formatted_address : '');
      this.$emit(
        'updatePlaceAndAddress',
        placeResultData ? `${placeResultData.name} - ${placeResultData.formatted_address}` : '',
      );
    },
    handleChange(text) {
      if (!text || !text.newVal || !text.newVal.trim) {
        this.$emit('updateCity', '');
        this.$emit('updateState', '');
        this.$emit('updateCounty', '');
        this.$emit('updatePlaceAndAddress', '');
      } else if (this.allowCustomEntries) {
        this.$emit('updateCity', text.newVal);
        this.$emit('updateState', text.newVal);
        this.$emit('updateCounty', text.newVal);
        this.$emit('updatePlaceAndAddress', text.newVal);
      }

      this.internalValue = text;
    },
    emitAddress() {
      if (this.internalValue.trim()) {
        this.$emit('onKeyEnter');
      }
    },
  },
};
</script>
<style scoped>
.input__label {
  transform: translateY(0.75rem);
}

.input:focus + .input__label,
.input--filled + .input__label {
  @apply font-bold;
  transform: translateY(0) scale(0.78);
}

.input:focus + .input__label {
  @apply text-primary;
}

.input:focus + .icon-wrapper {
  @apply text-primary;
}

.disabled {
  @apply bg-gray-200 shadow-none text-gray-700;
}
</style>
