var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative",class:[{ 'w-full': !_vm.customWidth }]},[(_vm.showChevron)?_c('ChevronDownIcon',{staticClass:"chevron-icon absolute right-0 w-2 mr-6 mt-5",class:{ focused: _vm.focused }}):_vm._e(),_c('DatePicker',{attrs:{"disabled-date":_vm.dateRestrictFunction,"value":_vm.value,"value-type":'M/D/YYYY'},on:{"change":_vm.updateFromCalendar},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('input',{ref:"input",staticClass:"input cursor-pointer h-full border w-full text-gray-900 bg-white rounded shadow text-sm placeholder-gray-500 focus:outline-none transition-colors duration-200 ease",class:[
          { 'h-56px': !_vm.customHeight },
          { 'input--filled': _vm.value && _vm.value.length > 0 },
          { 'border-red-600': _vm.invalid },
          { 'border-gray-300': !_vm.invalid },
          { disabled: _vm.disabled },
          { 'py-5 pl-12 pr-4': _vm.hasIcon && !_vm.showLabel && _vm.label && !_vm.compact },
          { 'py-3 pl-14 pr-4': _vm.hasIcon && _vm.showLabel && _vm.label && !_vm.compact },
          { 'pt-3 pb-3 px-4': !_vm.hasIcon && _vm.label && !_vm.compact },
          { 'py-6 px-4': !_vm.label && !_vm.compact },
          { 'py-2 pl-10 pr-2': _vm.hasIcon && _vm.compact },
          { 'py-2 px-4': !_vm.hasIcon && _vm.compact },
        ],attrs:{"readonly":_vm.readonly,"autocomplete":"off","autocorrect":"false","spellcheck":"false","input-class":'',"id":_vm.id,"name":_vm.name,"type":_vm.type,"placeholder":_vm.placeholder || (_vm.focused && _vm.focusedPlaceholder),"maxlength":_vm.maxLength,"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"input":_vm.updateValue,"focus":_vm.onFocus,"blur":function($event){_vm.focused = false}}}),(_vm.label)?[(!_vm.hasIcon)?_c('label',{staticClass:"input__label absolute left-0 text-gray-600 mx-4 pointer-events-none mt-1 origin-top-left transition duration-200 ease-out",class:[{ 'text-red-600': _vm.invalid }],attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),(_vm.hasIcon && _vm.showLabel)?_c('label',{staticClass:"input__label absolute left-0 text-gray-600 mt-2px mr-4 ml-14 pointer-events-none origin-top-left transition duration-200 ease-out",class:[{ 'text-red-600': _vm.invalid }],attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),(_vm.hasIcon)?_c('span',{staticClass:"icon-wrapper absolute top-0 left-0 h-full flex items-center ml-5 px-1"},[_c('label',{staticClass:"pointer-events-none opacity-0 w-0 h-0",attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.label))]),_vm._t("icon")],2):_vm._e()]:_vm._e()]},proxy:true}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }